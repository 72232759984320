<template>
  <div
    :style="slidePosition"
    class="w-100 h-100 d-flex flex-column align-items-center justify-content-center z-2"
  >
    <div class="slide2 w-75" ref="slide2">
      <h2
        class="text-warning font-vw-5 font-w-600 mb-0 fade2"
        style="opacity:0;"
      >
        TRIUMF’S STORY
      </h2>
      <h3
        class="text-warning font-vw-3-5 font-w-600 mb-0 pb-3 fade2"
        style="opacity:0;"
      >
        CURIOSITY. DISCOVERY. INNOVATION.
      </h3>
      <div class="line mx-auto" style="border-bottom: 2px solid white;"></div>
      <p class="text mt-3 text-white font-italic font-vw-1-35 mb-0">
        Developed by the Strategic Communications team in collaboration with
        Jean-Michel Poutissou, Ewart W Blackmore, Gordon Ball, Robert Laxdal and
        Rick Baartman. Based on historic events documented by Mike Craddock.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"

export default {
  props: ["globalUnits", "slideIndex"],
  methods: {
    slide2(percentage) {
      let animation2 = anime
        .timeline({
          autoplay: false,
          endDelay: 1000
        })
        .add({
          targets: ".line",
          width: ["0%", "100%"],
          duration: 800,
          elasticity: 600,
          delay: 1000,
          easing: "easeInCubic"
        })
        .add({
          targets: ".fade2",
          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 500,
          easing: "easeInOutCubic",
          delay: (el, i) => 100 * i
        })
        .add({
          targets: ".text",
          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 500,
          easing: "easeInOutCubic"
        })
      animation2.seek(
        percentage <= 0.5
          ? animation2.duration * (2 * percentage)
          : animation2.duration * (1 - percentage)
      )
    }
  },
  mounted() {
    this.slide2(this.innerPosition)
  },
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  watch: {
    innerPosition() {
      this.slide2(this.innerPosition)
    }
  }
}
</script>

<style></style>
